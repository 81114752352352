import { FC, memo, SyntheticEvent, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StringParam, useQueryParam } from 'use-query-params';

import { CanonicalContent, Contact, Context, Group, Message, Rating, Share } from '@/generated/graphql';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '@/hooks/watch-state';
import { useSnackbar } from '@/hooks/snackbar';
import { useNavigationActions } from '@/hooks/history';
import { testIccCollection } from '@/utils/url';
import { UserName } from '@/components/UserName';
import { SwipeableUnfurl } from '@/components/unfurl/SwipeableUnfurl';
import { CompactShareUnfurl, CompactShareUnfurlProps } from '@/components/unfurl/CompactShareUnfurl';
import { FollowButton } from '@/components/FollowButton';
import { ListShare, ListShareProps } from '@/components/Post/ListShare';
import { Avatar } from '@/components/Avatar';
import { Dialog, DialogProps } from '@/components/unfurl/Dialog';
import { ShareContext } from '@/components/ShareContext';
import { ShareText, ShareTextProps } from '@/components/ShareText';
import { RatingIcon } from '@/components/icons/RatingIcon';
import { ShareChatOverlay, ShareChatOverlayProps } from './ShareChatOverlay';
import { ActionIcons, ActionIconsProps } from '@/components/Post/ActionIcons';


export type FullShareProps = {
  feedItemId: string,
  share: Pick<Share, '_id' | 'commentCount'> & {
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    context?: Pick<Context, 'type'> & {
      group?: Pick<Group, 'name' | 'color' | '_id'> | null,
    } | null,
    message?: Pick<Message, 'link'> | null,
    sender?: Pick<Contact, '_id'> | null,
    sendersRating?: Pick<Rating, 'rating'> | null,
    list?: ListShareProps['list'] | null,
    comments?: ShareChatOverlayProps['comments'] | null,
  } & CompactShareUnfurlProps['share'] & ActionIconsProps['share'] & ShareTextProps['share'] & ShareChatOverlayProps['share'],
  canonicalContent?: Pick<CanonicalContent, '_id'> & DialogProps['canonicalContent'] & CompactShareUnfurlProps['canonicalContent'] | null,
  handleClick: (ev: SyntheticEvent) => void,
}


type FullShareWithParamsProps = FullShareProps & {
  openChatId?: string | null,
}


const sheetQueryParam = "comment-chat";


const FullShareWithParams: FC<FullShareWithParamsProps> = memo(function FullShareWithParams({ feedItemId, share, canonicalContent, handleClick, openChatId, ...rest }: FullShareWithParamsProps) {

  const theme = useTheme();

  const { watchedSnackbar, watchlistSnackbar } = useSnackbar(share, canonicalContent);

  const isWatched = useIsWatched(share?._id, share?.canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (v: boolean) => toggleWatched(share?.canonicalContent?._id, share?._id, v);
  const isInWatchlist = useIsInWatchlist(share?._id, share?.canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(share?.canonicalContent?._id, share?._id, v);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const { closeSheet } = useNavigationActions();

  let contextColor = theme.palette.primary.background;
  if(share.context) {
    if(share.context.type === 'public' || share.context.type === 'public-list') {
      contextColor = theme.palette.primary.background;
    } else if(share.context.type === 'group' && share.context.group?.name && share.context.group?.color) {
      const colorName = share.context.group.color;
      if(colorName in theme.palette.groups) {
        const c = colorName as keyof typeof theme.palette.groups;
        contextColor = theme.palette.groups[c].background;
      } else {
        contextColor = share.context.group.color;
      }
    }
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        p: 2,
        pb: 1.5,
        borderBottom: `1px solid ${theme.palette.grey['100']}`,
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        maxWidth: '100%'
      }}
      {...rest}
    >

      <Avatar
        sx={{ flex: '0 0 32px' }}
        size={'medium'}
        contact={share.sender?._id}
        link={true}
      />

      <Box sx={{ flex: 1, flexDirection: 'column', maxWidth: 'calc(100% - 40px)' }}>

        {share.sender &&
          <Box sx={{display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}>
            <UserName
              userId={share.sender?._id}
              addLink={true}
              postfix=' shared'
              sx={{ fontWeight: 500, flex: '0 0 auto' }}
            />
            {share.sendersRating &&
              <RatingIcon value={share.sendersRating.rating} highlight />
            }
            {share.context && <ShareContext context={share.context} />}
            <Box sx={{ flex: '1 1 auto' }}>
              <FollowButton contact={share.sender} lineHeight='20px' divider={false} />
            </Box>
          </Box>
        }

        <Box sx={{ pb: 0.5 }}>
          <ShareText share={share} link={false} color={theme.palette.background.paper} maxHeight={100} toggle={false} />
        </Box>

        <Box sx={{ maxWidth: '280px' }}>
          {share.message?.link && share.list && testIccCollection(share.message.link) ? (
            <ListShare list={share.list} sx={{ border: 'none', backgroundColor: 'grey.100' }} link={`/post/${share._id}/`} showUser={share.list.createdBy?._id !== share.sender?._id} />
          ) : (
            <SwipeableUnfurl
              isWatched={isWatched}
              setIsWatched={(v) => {
                setIsWatched(v);
                if(v) {
                  watchedSnackbar();
                }
              }}
              isInWatchlist={isInWatchlist}
              setIsInWatchlist={(v) => {
                setIsInWatchlist(v);
                if(v) {
                  watchlistSnackbar(() => setDialogOpen(true));
                }
              }}
            >
              <CompactShareUnfurl
                share={share}
                canonicalContent={canonicalContent}
                sx={{ border: 'none', backgroundColor: 'grey.100' }}
                setDialogOpen={setDialogOpen}
              />
            </SwipeableUnfurl>
          )}
        </Box>

        {dialogOpen &&
          <Dialog share={share} canonicalContent={canonicalContent || undefined} open={dialogOpen} setOpen={setDialogOpen} />
        }

        <ActionIcons share={share} openChatValue={feedItemId} canonicalContent={canonicalContent} />

      </Box>

      {openChatId === feedItemId &&
        <ShareChatOverlay comments={share.comments || []} share={share} canonicalContent={canonicalContent} onClose={() => closeSheet(sheetQueryParam)} setDialogOpen={setDialogOpen} contextColor={contextColor} />
      }

    </Box>
  );
});


export const FullShare: FC<FullShareProps> = function FullShare(props) {
  const [openChatId] = useQueryParam(sheetQueryParam, StringParam);
  return <FullShareWithParams {...props} openChatId={openChatId} />;
}
