import { FC, memo, SyntheticEvent, useState } from 'react';
import { Box, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StringParam, useQueryParam } from 'use-query-params';

import { CanonicalContent, Contact, List, Message, Rating, Share } from '@/generated/graphql';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '@/hooks/watch-state';
import { useSnackbar } from '@/hooks/snackbar';
import { testIccCollection } from '@/utils/url';
import { useNavigationActions } from '@/hooks/history';
import { UserName } from '@/components/UserName';
import { SwipeableUnfurl } from '@/components/unfurl/SwipeableUnfurl';
import { CompactShareUnfurl, CompactShareUnfurlProps } from '@/components/unfurl/CompactShareUnfurl';
import { FollowButton } from '@/components/FollowButton';
import { ListShare, ListShareProps } from '@/components/Post/ListShare';
import { Avatar } from '@/components/Avatar';
import { Dialog, DialogProps } from '@/components/unfurl/Dialog';
import { ShareText, ShareTextProps } from '@/components/ShareText';
import { RatingIcon } from '@/components/icons/RatingIcon';
import { ShareContext, ShareContextProps } from '@/components/ShareContext';
import { ActionIcons, ActionIconsProps } from '@/components/Post/ActionIcons';
import { ShareChatOverlay, ShareChatOverlayProps } from '@/views/feed/items/ShareChatOverlay';


export type FullShareProps = {
  share: Pick<Share, '_id'> & {
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    context?: ShareContextProps['context'] | null,
    message: Pick<Message, 'link'> | null,
    sender?: Pick<Contact, '_id'> | null,
    sendersRating?: Pick<Rating, 'rating'> | null,
    list?: Pick<List, '_id'> & ListShareProps['list'] & {
      createdBy?: Pick<Contact, '_id'> | null,
    } | null,
    unfurl: CompactShareUnfurlProps['share']['unfurl'],
    comments?: ShareChatOverlayProps['comments'] | null,
  } & ShareTextProps['share'] & CompactShareUnfurlProps['share'] & ActionIconsProps['share'] & ShareChatOverlayProps['share'],
  canonicalContent?: Pick<CanonicalContent, '_id'> & DialogProps['canonicalContent'] & CompactShareUnfurlProps['canonicalContent'] & ActionIconsProps['canonicalContent'] | null
  handleClick: (ev: SyntheticEvent) => void,
}


type FullShareWithParamsProps = FullShareProps & {
  openChatId?: string | null,
}


const sheetQueryParam = "comment-chat";


const FullShareWithParams: FC<FullShareWithParamsProps> = memo(function FullShareWithParams({ share, canonicalContent, handleClick, openChatId, ...rest }: FullShareWithParamsProps) {

  const theme = useTheme();

  const { watchedSnackbar, watchlistSnackbar } = useSnackbar(share, canonicalContent);

  const isWatched = useIsWatched(share?._id, share?.canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (v: boolean) => toggleWatched(share?.canonicalContent?._id, share?._id, v);
  const isInWatchlist = useIsInWatchlist(share?._id, share?.canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(share?.canonicalContent?._id, share?._id, v);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const { closeSheet } = useNavigationActions();

  return (
    <Card
      onClick={handleClick}
      sx={{
        p: 2,
        pb: 1.5,
        borderBottom: `1px solid ${theme.palette.grey['100']}`,
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        maxWidth: '100%'
      }}
      {...rest}
    >

      <Avatar
        sx={{ flex: '0 0 32px' }}
        size={'medium'}
        contact={share.sender?._id}
        link={true}
      />

      <Box sx={{ flex: 1, flexDirection: 'column', maxWidth: 'calc(100% - 40px)' }}>

        {share.sender &&
          <Box sx={{display: "flex", alignItems: "center", gap: 1 }}>
            <UserName
              userId={share.sender?._id}
              addLink={true}
              postfix=' shared'
              sx={{ fontWeight: 500, flex: '0 0 auto' }}
            />
            {share.sendersRating &&
              <RatingIcon value={share.sendersRating.rating} highlight />
            }
            {share.context && <ShareContext context={share.context} />}
            <Box sx={{ flex: '1 1 auto' }}>
              <FollowButton contact={share.sender} lineHeight='20px' divider={false} />
            </Box>
          </Box>
        }

        <ShareText share={share} link={false} color={theme.palette.background.paper} maxHeight={100} toggle={false} sx={{ mb: 0.5 }} />

        <Box sx={{ maxWidth: '280px' }}>
          {share.message?.link && share.list && testIccCollection(share.message.link) ? (
            <ListShare list={share.list} sx={{ border: 'none', backgroundColor: 'grey.100' }} link={`/post/${share._id}/`} showUser={share.list.createdBy?._id !== share.sender?._id} />
          ) : (
            share.unfurl && (
              <SwipeableUnfurl
                isWatched={isWatched}
                setIsWatched={(v) => {
                  setIsWatched(v);
                  if(v) {
                    watchedSnackbar();
                  }
                }}
                isInWatchlist={isInWatchlist}
                setIsInWatchlist={(v) => {
                  setIsInWatchlist(v);
                  if(v) {
                    watchlistSnackbar(() => setDialogOpen(true));
                  }
                }}
              >
                <CompactShareUnfurl
                  share={{ ...share, unfurl: share.unfurl }}
                  canonicalContent={canonicalContent}
                  sx={{ border: 'none', backgroundColor: 'grey.100' }}
                  setDialogOpen={setDialogOpen}
                />
              </SwipeableUnfurl>
            )
          )}
        </Box>

        {dialogOpen &&
          <Dialog share={share} canonicalContent={canonicalContent || undefined} open={dialogOpen} setOpen={setDialogOpen} />
        }

        <ActionIcons share={share} openChatValue={share._id} canonicalContent={canonicalContent} />

        {openChatId === share._id &&
          <ShareChatOverlay comments={share.comments || []} share={share} canonicalContent={canonicalContent} onClose={() => closeSheet(sheetQueryParam)} setDialogOpen={setDialogOpen} contextColor={theme.palette.primary} />
        }

      </Box>

    </Card>
  );
});


export const FullShare: FC<FullShareProps> = function FullShare(props) {
  const [openChatId] = useQueryParam(sheetQueryParam, StringParam);
  return <FullShareWithParams {...props} openChatId={openChatId} />;
}
